import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { EventsItem, EventsItemDetails } from './eventsitem';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }

  public updateStatus(eventId: number, status: string): Observable<EventsItem> {
    const url = `${environment.apiUrl}/events/${eventId}/status`;
    return this.http.put<EventsItem>(url, {Status: status}, httpOptions);
  }

  public getDetails(eventId: number): Observable<EventsItemDetails> {
    const url = `${environment.apiUrl}/events/${eventId}/details`;
    return this.http.get<EventsItemDetails>(url, httpOptions);
  }
}
