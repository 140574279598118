import { Component, ViewChild, Input } from '@angular/core';
import { } from '@types/googlemaps';
import { EventsItem, EventsItemDetails, Address } from '../eventsitem';
import { EventService } from '../event.service';
import 'rxjs/add/operator/finally';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent {
  @ViewChild('gmap') gmapElement: any;
  map: google.maps.Map;

  @Input()
  event: EventsItem;

  @Input() set expanded(expanded: boolean) {
    if (expanded) {
      this.loadEventDetails(this.event.ID);
    }
  }

  loaded: boolean;
  eventDetails: EventsItemDetails;

  eventAddressString: string;
  userAddressString: string;

  constructor(public eventService: EventService) { }

  loadEventDetails(id: number) {
    this.eventService.getDetails(id).finally(() => {
      this.loaded = true;
    }).subscribe(
      (eventDetails: EventsItemDetails) => {
        this.eventDetails = eventDetails;

        this.eventAddressString = this.getAddressString(eventDetails.Address);
        this.userAddressString = this.getAddressString(eventDetails.User.Address);

        const pos = new google.maps.LatLng(eventDetails.Latitude, eventDetails.Longitude)

        const mapProp = {
          center: pos,
          zoom: 15,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
        const marker = new google.maps.Marker({
          position: pos,
          map: this.map
        });
      },
      () => {
      }
    );
  }


  getAddressString(address: Address) {
    if (!address) {
      return '';
    }
    let addressString = '';
    if (address.street) {
      addressString = address.street;
    }
    if (address.city) {
      if (addressString.length > 0) {
        addressString = `${addressString}, ${address.city}`;
      } else {
        addressString = address.city;
      }
    }
    if (address.county) {
      if (addressString.length > 0) {
        addressString = `${addressString}, ${address.county}`;
      } else {
        addressString = address.county;
      }
    }
    if (address.country) {
      if (addressString.length > 0) {
        addressString = `${addressString}, ${address.country}`;
      } else {
        addressString = address.country;
      }
    }
    if (address.postalCode) {
      if (addressString.length > 0) {
        addressString = `${addressString}, ${address.postalCode}`;
      } else {
        addressString = address.postalCode;
      }
    }
    return addressString;
  }
}
