import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatSnackBar, MatSnackBarRef } from '@angular/material';
import { map } from 'rxjs/operators';
import { Observable, of as observableOf, merge, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { SocketService } from '../socket.service';
import { EventsItem } from '../eventsitem';

/**
 * Data source for the Events view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class EventsDataSource extends DataSource<EventsItem> {
  data: EventsItem[] = [];
  snackBarRef: MatSnackBarRef<any>;

  constructor(private paginator: MatPaginator, private sort: MatSort, public snackBar: MatSnackBar, private wsService: SocketService) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<EventsItem[]> {

    const url = `${environment.apiUrl}/events`.replace('https://', 'wss://').replace('http://', 'ws://');
    
    this.wsService.subscribe(url)
      .subscribe(
        message =>  {
          if (message === 'Connection error') {
            this.snackBarRef = this.snackBar.open(message, '', {duration: 2000});
          } else {
            this.data = JSON.parse(message);
            // stupid hack
            this.paginator._changePageSize(this.paginator.pageSize);
            if (!!this.snackBarRef) {
              this.snackBarRef.dismiss();
            }
          }

        }
    );

    // Combine everything that affects the rendered data into one update
    // stream for the data-table to consume.
    const dataMutations = [
      observableOf(this.data),
      this.paginator.page,
      this.sort.sortChange
    ];

    // Set the paginators length
    this.paginator.length = this.data.length;

    return merge(...dataMutations).pipe(map(() => {
      return this.getPagedData(this.getSortedData([...this.data]));
    }));
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect() {
    this.wsService.unsubscribe();
  }

  /**
   * Paginate the data (client-side). If you're using server-side pagination,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getPagedData(data: EventsItem[]) {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    return data.splice(startIndex, this.paginator.pageSize);
  }

  /**
   * Sort the data (client-side). If you're using server-side sorting,
   * this would be replaced by requesting the appropriate data from the server.
   */
  private getSortedData(data: EventsItem[]) {
    if (!this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'ID': return compare(+a.ID, +b.ID, isAsc);
        case 'Address': return compare(+a.Address, +b.Address, isAsc);
        case 'Latitude': return compare(a.Latitude, b.Latitude, isAsc);
        case 'Longitude': return compare(a.Longitude, b.Longitude, isAsc);
        case 'Types': return compare(a.Types, b.Types, isAsc);
        case 'PhoneNumber': return compare(a.PhoneNumber, b.PhoneNumber, isAsc);
        case 'CarNumber': return compare(a.CarNumber, b.CarNumber, isAsc);
        case 'Email': return compare(a.Email, b.Email, isAsc);
        case 'Name': return compare(a.Name, b.Name, isAsc);
        case 'AutoliittoNumber': return compare(a.AutoliittoNumber, b.AutoliittoNumber, isAsc);
        case 'Status': return compare(a.Status, b.Status, isAsc);
        default: return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
