import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Registration } from '../registration';
import { RegistrationService } from '../registration.service';
import { Router } from '@angular/router';
import { mustMatch } from '../validators';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  registrationForm: FormGroup;
  errorMsg: string;

  constructor(public fb: FormBuilder,
              private registrationService: RegistrationService,
              private router: Router) {

    this.registrationForm = fb.group({
      Email: ['', [Validators.required,  Validators.email]],
      Name: ['', []],
      Password: ['', [Validators.required, Validators.minLength(8)]],
      Password2: ['', [Validators.required, Validators.minLength(8)]]
    }, {validator: mustMatch('Password', 'Password2', 'passwordMismatch')});
  }

  ngOnInit() {
  }

  register(): void {
    if (this.registrationForm.valid) {
      this.registrationService.register(this.registrationForm.value)
        .subscribe(
          () => {
            this.router.navigate(['/login']);
          },
          (error) => {
            this.errorMsg = error.error.Message || error.statusText;
          }
        );
    }
  }
}
