import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errorMsg: string;

  constructor(public fb: FormBuilder,
              private authService: AuthService,
              private router: Router) {

    this.loginForm = fb.group({
      Email: ['', [Validators.required]],
      Password: ['', [Validators.required]]
    });
  }

  ngOnInit() {
  }

  login(): void {
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.controls.Email.value, this.loginForm.controls.Password.value)
        .subscribe(
          () => {
            this.router.navigate(['/events']);
          },
          (error) => {
            this.errorMsg = error.statusText;
          }
        );
    }
  }
}
