import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private ws: WebSocket;
  private url: string;
  private retryTimeout: any;
  private unsubscribed: boolean;
  private observer: Observer<any>;
  private retryIntervalInSeconds: number = 2;

  constructor(private authService: AuthService,
              private router: Router) {}

  private setCookie(name: string, value: string) {
    document.cookie = name + '=' + (value || '') + '; path=/';
  }

  private connect(url: string) {
    this.setCookie('auth', localStorage.getItem('id_token'));
    this.ws = new WebSocket(url);

    this.ws.onopen = (event) => {
      console.log('opened WebSocket');
      this.ws.onmessage = (e) => this.observer.next(e.data);
    };

    this.ws.onclose = (event) => {
      console.log('no connection');

      if (!this.unsubscribed) {
        console.log('retrying...');
        this.retryTimeout = setTimeout(() => { this.connect(url); }, this.retryIntervalInSeconds * 1000);
      }
    };

    this.ws.onerror = (err) => {
      console.log(err);
      if (this.authService.isLoggedOut()) {
        this.router.navigate(['/login']);
      } else {
        this.observer.next('Connection error'); // do not send error event to observer - events after reconnection will not arrive.
      }
    };
  }

  subscribe(url: string): Observable<string> {
    this.unsubscribed = false;
    return new Observable(observer => {
      this.observer = observer;
      this.connect(url);
      return () => {
        console.log('Finito!');
      };
    });
  }

  unsubscribe() {
    console.log('unsubscribing');
    this.unsubscribed = true;
    if (!!this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }
    if (!!this.ws) {
      this.ws.close();
      this.ws = null;
    }
  }
}
