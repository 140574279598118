import { FormGroup } from '@angular/forms';

export function mustMatch(controlName1: string, controlName2: string, errorName: string) {
  return (group: FormGroup): any => {

    if (group.controls[controlName1].value !== group.controls[controlName2].value) {
      return group.controls[controlName2].setErrors({errorName: true});
    } else {
      // to make sure that error is removed when changes to control1 create a match
      return group.controls[controlName2].setErrors(group.controls[controlName2].validator(group.controls[controlName2]));
    }
  };
}
