import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.css']
})
export class AppNavComponent implements OnInit {
  sessionInfo: string;

  constructor(private authService: AuthService,
              private router: Router) {}

  ngOnInit() {
    this.startSessionExpiryTimer();
  }

  startSessionExpiryTimer() {
    setInterval(() => {
        if (!this.isLoggedIn()) {
          this.sessionInfo = 'Not logged in';
        } else {
          this.sessionInfo = `Session expires in: ${this.authService.getExpiresInTime()}`;
        }
      }, 1000);
  }

  logout() {
    this.authService.logout();
    if (this.authService.isLoggedOut()) {
      this.router.navigate(['/login']);
    }
  }

  isLoggedIn() {
    return this.authService.isLoggedIn();
  }
}
