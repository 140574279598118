import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) {
  }

  login(id: string, password: string ) {
    const url = `${environment.apiUrl}/login`;
    return this.http.post<any>(url, {id, password}).pipe(
      tap(token => this.setSession(token))
    );
  }

  refreshToken() {
    const url = `${environment.apiUrl}/refresh_token`;
    return this.http.get(url);
  }

  setSession(token: any) {
    const expiresAt = moment(jwt_decode(token.token).exp * 1000); // api (golang) returns the number of seconds elapsed since January 1, 1970 UTC. JS expects milliseconds.
    localStorage.setItem('id_token', token.token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()) );
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
  }

  isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  getExpiresInTime() {
    let ms = this.getExpiration().diff(moment());
    ms = Math.max(ms, 0);
    const d = moment.duration(ms);
    return Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");
  }
}
