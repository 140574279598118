import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import * as moment from 'moment';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  timeout: number = 10000;
  refreshMinutesBeforeExpiration: number = 10;

  constructor(private injector: Injector,
              private router: Router,
              private authService: AuthService) {}

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.isAuthenticatedRequest(req)) {
      if (this.authService.isLoggedIn()) {
        if (this.tokenExpiresSoon() && !req.url.endsWith('/refresh_token')) {
          const http = this.injector.get(HttpClient);
          const url = `${environment.apiUrl}/refresh_token`;
          return http.get(url).mergeMap(token => {
            this.authService.setSession(token);
            console.log("refreshed token");
            return this.handle(this.addCurrentTokenToHeader(req), next);
          });
        }
        return this.handle(this.addCurrentTokenToHeader(req), next);
      } else {
        this.router.navigate(['/login']);
      }
    } else {
      return this.handle(req, next);
    }
  }

  private tokenExpiresSoon() {
    return moment().add(this.refreshMinutesBeforeExpiration*60, 'second').isAfter(this.authService.getExpiration());
  }

  private isAuthenticatedRequest(req: HttpRequest<any>) {
    return !req.url.endsWith('/login') &&
      !req.url.endsWith('/register');
  }

  private addCurrentTokenToHeader(req: HttpRequest<any>) {
    const token = localStorage.getItem('id_token');
    return req.clone({
      headers: req.headers.set('Authorization',
        `Bearer ${token}`)
    });
  }

  private handle(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).timeout(this.timeout).do((event: HttpEvent<any>) => {}, (err: any) => {
      if (err instanceof HttpErrorResponse && err.status === 401) {
        this.router.navigate(['/login']);
      }
    });
  }
}