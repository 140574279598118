import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatSnackBar, MatOptionSelectionChange } from '@angular/material';
import { EventsDataSource } from './events-datasource';
import { EventService } from '../event.service';
import { EventsItem } from '../eventsitem';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { EventDetailsComponent } from '../event-details/event-details.component';
import { SocketService } from '../socket.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EventsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: EventsDataSource;

  constructor(public snackBar: MatSnackBar, public eventService: EventService, public wsService: SocketService) {}

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['ID', 'Address', 'Types', 'PhoneNumber', 'CarNumber', 'Name', 'AutoliittoNumber', 'Status'];
  statuses = ['PENDING', 'RECEIVED', 'IN_PROGRESS', 'DONE'];
  expandedEvent: EventsItem;

  ngOnInit() {
    this.dataSource = new EventsDataSource(this.paginator, this.sort, this.snackBar, this.wsService);
  }

  toggleEventDetails(event: any, row: EventsItem) {
    if ((event.target as Element).nodeName != "MAT-CELL") {
      return;
    }
    if (this.expandedEvent === row) {
      this.expandedEvent = null;
    } else {
      this.expandedEvent = row;
    }
  }

  updateStatus (e: MatOptionSelectionChange, eventsItem: EventsItem) {
    this.eventService.updateStatus(eventsItem.ID, eventsItem.Status)
      .subscribe(
        (updatedEventsItem) => {
          console.log(`Event.ID = ${eventsItem.ID} status set to ${updatedEventsItem.Status} successfully`);
        },
        (error) => {
          console.log(error);
          let message = error.error.Message || error.statusText;
          this.snackBar.open(message, '', {duration: 2000});
        }
      );
  }
}
